import { useEffect, useState } from "react";
import { requestToServer } from "../utils/appUtils";
import CardHeaderDashboard from "../home/cardHeaderDashboard";

let monitorTypes = [
  { id: "filesystem", name: "Диски", height: "300px" }, { id: "top", name: "Процессы", height: "300px" },
  { id: "vmstat", name: "Память", height: "460px" }, { id: "netstat", name: "Сеть", height: "460px" },
]

function AccessObjects() {
  return (
    <div className="container-fluid px-3 py-1 dashboard">
      <div className="row g-3">
        {(
          monitorTypes.map((item, index, array) => {
            return (
              <div key={item.id} className="col-md-12 col-lg-12 col-xl-6">
                <CommandCard caption={item.name} type={item.id} height={item.height} />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

function CommandCard({ caption, type, height }) {
  const [loading, setLoading] = useState(false);
  const [monitorData, setMonitorData] = useState("");

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    refreshData();
  }

  const refreshData = () => {
    let url = new URL(process.env.REACT_APP_API_URL + "/dashboard/monitor");
    url.searchParams.set('type', type);
    setLoading(true);
    requestToServer(url.href, { method: 'GET' }, (response) => {
      setMonitorData(response.data);
      setLoading(false);
    }, (error) => { setLoading(false); console.error('dashboard', error) }, false);
  }

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <div className="card h-100">
      <CardHeaderDashboard loading={loading} onClick={handleClick}>{caption}</CardHeaderDashboard>
      <div className="card-body d-flex flex-column" style={{ height: height }}>
        <pre className="flex-grow-1 my-0" style={{ fontSize: "1em" }}>{monitorData}</pre>
      </div>
    </div>
  );
}

export default AccessObjects;
