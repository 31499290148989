import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $, { data } from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../utils/appUtils";
import TableDT from "../../UI/TableDT";

const ROUTE_URL = "/get_conn_knap";

function Knapdata(props) {
    const [serverData, setServerData] = useState(null);
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();

    const handleAddRow = () => {
        navigate("./create");
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlStr = "./" + data.id;
            navigate(urlStr);
        });

    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
    }, []);



    console.log('serverData', serverData);

    if (serverData === null)
        return <MainArea loading />;

    return (
        <MainArea>
            <MainAreaHeader caption="Данные НАП" />
            <MainAreaBody containerClass="">
                {/* {
                    <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
                } */}
                <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
            </MainAreaBody>
        </MainArea>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?track" },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                // { data: "id", title: "Код", className: "dt-body-right", },
                // { data: "type", title: "Тип", render: DataTable.render.text(), },
                { data: "timestamp", title: "Временная метка", render: DataTable.render.datetime(), },
                { data: "receiver_timestamp", title: "Время отправки", render: DataTable.render.datetime(), },
                { data: "is_valid", title: "Флаг достоверности решения НЗ", render: DataTable.render.number(), },
                { data: "latitude", title: "Широта", render: DataTable.render.number(), },
                { data: "longitude", title: "Долгота", render: DataTable.render.number(), },
                { data: "altitude", title: "Высота над эллипсоидом", render: DataTable.render.number(), },
                { data: "speed", title: "Плановая скорость", render: DataTable.render.number(), },
                { data: "yaw", title: "Курс", render: DataTable.render.number(), },
                { data: "used_psats", title: "Количество ПС в решении", render: DataTable.render.number(), },
                { data: "valid_count", title: "Количество достоверных решений НЗ подряд", render: DataTable.render.number(), },
                { data: "gdop", title: "GDOP", render: DataTable.render.number(), },
                { data: "pdop", title: "PDOP", render: DataTable.render.number(), },
                // { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
                // { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        console.log('dtOptions', dtOptions);


        return dtOptions;
    }
};

export default Knapdata;
