import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../utils/appUtils";
import TableDT from "../../UI/TableDT";

const ROUTE_URL = "/get_conn_mc";

function Msconnect(props) {
    const [serverData, setServerData] = useState(null);
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();

    const handleAddRow = () => {
        navigate("./create");
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable1.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable1.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable1.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable1.Api.row($(this).parents('tr')).data();
            let urlStr = "./" + data.id;
            navigate(urlStr);
        });
    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
    }, []);


    if (serverData === null)
        return <MainArea loading />;

    return (

        <div className="container-fluid px-3 py-1 dashboard">
            <div className="row g-3">
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <TableDT getOptions={() => dtTable1.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />

                </div>
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <TableDT getOptions={() => dtTable2.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />

                </div>
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <TableDT getOptions={() => dtTable3.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />

                </div>
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <TableDT getOptions={() => dtTable4.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />

                </div>
            </div>
        </div>

        // <>
        // <MainArea>
        //     {/* <MainAreaHeader caption="Связь с МС" /> */}
        //     <MainAreaBody containerClass="">
        //         {
        //             // (serverData.crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
        //         }
        //         <TableDT getOptions={() => dtTable1.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
        //         <TableDT getOptions={() => dtTable2.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
        //         <TableDT getOptions={() => dtTable3.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
        //         <TableDT getOptions={() => dtTable4.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
        //     </MainAreaBody>
        // </MainArea>
        // <MainArea>
        //     {/* <MainAreaHeader caption="Связь с МС" /> */}
        //     <MainAreaBody containerClass="">
        //         {
        //             // (serverData.crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
        //         }
        //         <TableDT getOptions={() => dtTable2.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
        //     </MainAreaBody>
        // </MainArea>
        // </>

    );

}

let dtTable1 = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?kks_psats_info&1" },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                // { data: "id", title: "Код", className: "dt-body-right", },
                // { data: "type", title: "Тип", render: DataTable.render.text(), },
                { data: "0", title: "Временная метка", render: DataTable.render.date(), },
                { data: "ps_number", title: "Номер МС (ЛСН) в системе", render: DataTable.render.number(), },

                { data: "ip_address", title: "IP адрес МС в служебном канале ЛСН", render: DataTable.render.text(), },

                // { data: "is_valid", title: "Значения датчиков текущего состояния МС (ЛСН)", render: DataTable.render.number(), },
                // { data: "sensors?.dc_voltage", title: "Напряжение питания МС", render: DataTable.render.number(), },
                // { data: "sensors?.fnk_current", title: "Ток потребления формирователем сигнала МС", render: DataTable.render.number(), },
                // { data: "sensors?.modem_current", title: "Ток потребления аппаратурой служебного канала", render: DataTable.render.number(), },

                // { data: "latitude", title: "Координаты положения фазового центра передающей антенны данной МС (ЛСН) в ECEF (X,Y,Z) (заданные)", render: DataTable.render.number(), },
                { data: "coords.latitude", title: "Широта", render: DataTable.render.text(), },
                { data: "coords.longitude", title: "Долгота", render: DataTable.render.text(), },
                { data: "coords.altitude", title: "Высота", render: DataTable.render.text(), },

                { data: "rf_power", title: "Текущее значение мощности", render: DataTable.render.number(), },


                { data: "tracking_duration", title: "Время захвата сигнала МС (ЛСН) на СЧ ПУ ЛСН", render: DataTable.render.number(), },

                // { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
                // { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        return dtOptions;
    }
};

let dtTable2 = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?kks_psats_info&2" },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                // { data: "id", title: "Код", className: "dt-body-right", },
                // { data: "type", title: "Тип", render: DataTable.render.text(), },
                { data: "timestamp", title: "Временная метка", render: DataTable.render.date(), },
                { data: "ps_number", title: "Номер МС (ЛСН) в системе", render: DataTable.render.number(), },

                { data: "ip_address", title: "IP адрес МС в служебном канале ЛСН", render: DataTable.render.text(), },

                // { data: "is_valid", title: "Значения датчиков текущего состояния МС (ЛСН)", render: DataTable.render.number(), },
                // { data: "sensors.dc_voltage?", title: "Напряжение питания МС", render: DataTable.render.number(), },
                // { data: "sensors.fnk_current?", title: "Ток потребления формирователем сигнала МС", render: DataTable.render.number(), },
                // { data: "sensors.modem_current?", title: "Ток потребления аппаратурой служебного канала", render: DataTable.render.number(), },

                // { data: "latitude", title: "Координаты положения фазового центра передающей антенны данной МС (ЛСН) в ECEF (X,Y,Z) (заданные)", render: DataTable.render.number(), },
                { data: "coords.latitude", title: "Широта", render: DataTable.render.text(), },
                { data: "coords.longitude", title: "Долгота", render: DataTable.render.text(), },
                { data: "coords.altitude", title: "Высота", render: DataTable.render.text(), },

                { data: "rf_power", title: "Текущее значение мощности", render: DataTable.render.number(), },


                { data: "tracking_duration", title: "Время захвата сигнала МС (ЛСН) на СЧ ПУ ЛСН", render: DataTable.render.number(), },

                // { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
                // { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        return dtOptions;
    }
};

let dtTable3 = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?kks_psats_info&3" },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                // { data: "id", title: "Код", className: "dt-body-right", },
                // { data: "type", title: "Тип", render: DataTable.render.text(), },
                { data: "timestamp", title: "Временная метка", render: DataTable.render.date(), },
                { data: "ps_number", title: "Номер МС (ЛСН) в системе", render: DataTable.render.number(), },

                { data: "ip_address", title: "IP адрес МС в служебном канале ЛСН", render: DataTable.render.text(), },

                // { data: "is_valid", title: "Значения датчиков текущего состояния МС (ЛСН)", render: DataTable.render.number(), },
                // { data: "sensors.dc_voltage?", title: "Напряжение питания МС", render: DataTable.render.number(), },
                // { data: "sensors.fnk_current?", title: "Ток потребления формирователем сигнала МС", render: DataTable.render.number(), },
                // { data: "sensors.modem_current?", title: "Ток потребления аппаратурой служебного канала", render: DataTable.render.number(), },

                // { data: "latitude", title: "Координаты положения фазового центра передающей антенны данной МС (ЛСН) в ECEF (X,Y,Z) (заданные)", render: DataTable.render.number(), },
                { data: "coords.latitude", title: "Широта", render: DataTable.render.text(), },
                { data: "coords.longitude", title: "Долгота", render: DataTable.render.text(), },
                { data: "coords.altitude", title: "Высота", render: DataTable.render.text(), },

                { data: "rf_power", title: "Текущее значение мощности", render: DataTable.render.number(), },


                { data: "tracking_duration", title: "Время захвата сигнала МС (ЛСН) на СЧ ПУ ЛСН", render: DataTable.render.number(), },

                // { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
                // { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        return dtOptions;
    }
};

let dtTable4 = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?kks_psats_info&4" },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                // { data: "id", title: "Код", className: "dt-body-right", },
                // { data: "type", title: "Тип", render: DataTable.render.text(), },
                { data: "timestamp", title: "Временная метка", render: DataTable.render.date(), },
                { data: "ps_number", title: "Номер МС (ЛСН) в системе", render: DataTable.render.number(), },

                { data: "ip_address", title: "IP адрес МС в служебном канале ЛСН", render: DataTable.render.text(), },

                // { data: "is_valid", title: "Значения датчиков текущего состояния МС (ЛСН)", render: DataTable.render.number(), },
                // { data: "sensors.dc_voltage", title: "Напряжение питания МС", render: DataTable.render.number(), },
                // { data: "sensors.fnk_current", title: "Ток потребления формирователем сигнала МС", render: DataTable.render.number(), },
                // { data: "sensors.modem_current", title: "Ток потребления аппаратурой служебного канала", render: DataTable.render.number(), },

                // { data: "latitude", title: "Координаты положения фазового центра передающей антенны данной МС (ЛСН) в ECEF (X,Y,Z) (заданные)", render: DataTable.render.number(), },
                { data: "coords.latitude", title: "Широта", render: DataTable.render.text(), },
                { data: "coords.longitude", title: "Долгота", render: DataTable.render.text(), },
                { data: "coords.altitude", title: "Высота", render: DataTable.render.text(), },

                { data: "rf_power", title: "Текущее значение мощности", render: DataTable.render.number(), },


                { data: "tracking_duration", title: "Время захвата сигнала МС (ЛСН) на СЧ ПУ ЛСН", render: DataTable.render.number(), },

                // { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
                // { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        return dtOptions;
    }
};




export default Msconnect;
