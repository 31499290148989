// import { Routes, Route } from "react-router-dom";
import { createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";

import "./components/utils/DataTablesUtils";
import Layout from "./components/layout/Layout.jsx";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ErrorPage from "./components/ErrorPage.js";
//import Game from "./components/game/Game";
import Home from "./components/home/Home";
import Login from "./components/auth/Login";
import ChangePassword from "./components/auth/ChangePassword";

import Rules from "./components/access/Rules.jsx";
import Roles from "./components/access/Roles.jsx";
import SettingAccess from "./components/access/SettingAccess.jsx";
import SysUsers from "./components/access/SysUsers.jsx";
import SysUser from "./components/access/SysUser.jsx";
import LogLists from "./components/access/LogLists.jsx";

import Knapdata from "./components/page/Control/VpyData.jsx";
import Lsnstate from "./components/page/Control/LsnState.jsx";
import Msconnect from "./components/page/Control/MsConnect.jsx";
import CHpylsnConnect from "./components/page/Control/CHpylsnConnect.jsx";
import VpylsnConnect from "./components/page/Control/VpylsnConnect.jsx";
import NapData from "./components/page/Protocols/NapData.jsx";
import MsData from "./components/page/Protocols/MsData.jsx";
import CHpylsnData from "./components/page/Protocols/CHpylsnData.jsx";
import VpyData from "./components/page/Protocols/VpyData.jsx";
import Lsn from "./components/page/Configuration/Lsn.jsx";
import MfuncStations from "./components/page/Configuration/MfuncStations.jsx";
import LoginHistory from "./components/access/LoginHistory.jsx";
import Events from "./components/others/Events.jsx";
import VirtualEnv from "./components/others/VirtualEnv.jsx";
import AccessObjects from "./components/others/AccessObjects.jsx";

const App = createBrowserRouter(
   createRoutesFromElements(
      <>
         <Route path="/login" element={<Login />} />
         <Route path="/forgot_password" element={<ForgotPassword />} />
         <Route path="/reset_password/:token/:login" element={<ResetPassword />} />
         <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
            <Route index element={<Home />} />
            {/* <Route index element={<Lsnstate />} /> */}
            <Route path="changepassword" element={<ChangePassword />} errorElement={<ErrorPage />} />
            <Route path="rules" element={<Rules />} errorElement={<ErrorPage />} />
            <Route path="rules/:id/roles" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles" element={<Roles />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/rules" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/sys_users" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="sys_users" element={<SysUsers />} errorElement={<ErrorPage />} />
            <Route path="sys_users/:id" element={<SysUser />} errorElement={<ErrorPage />} />
            <Route path="log_lists" element={<LogLists />} errorElement={<ErrorPage />} />
            <Route path="loginhistory" element={<Events />} errorElement={<ErrorPage />} />
            <Route path="virtual_env" element={<VirtualEnv />} errorElement={<ErrorPage />} />
            <Route path="access_objects" element={<AccessObjects />} errorElement={<ErrorPage />} />

            {/* <Route path="operators" element={<Operators />} errorElement={<ErrorPage />} /> */}

            <Route path="knapdata" element={<Knapdata />} errorElement={<ErrorPage />} />
            <Route path="lsnstate" element={<Lsnstate />} errorElement={<ErrorPage />} />
            <Route path="msconnect" element={<Msconnect />} errorElement={<ErrorPage />} />
            <Route path="chpylsnconnect" element={<CHpylsnConnect />} errorElement={<ErrorPage />} />
            <Route path="vpylsnconnect" element={<VpylsnConnect />} errorElement={<ErrorPage />} />

            <Route path="napdata" element={<NapData />} errorElement={<ErrorPage />} />
            <Route path="msdata" element={<MsData />} errorElement={<ErrorPage />} />
            <Route path="chpylsndata" element={<CHpylsnData />} errorElement={<ErrorPage />} />
            <Route path="vpydata" element={<VpyData />} errorElement={<ErrorPage />} />

            <Route path="lsn" element={<Lsn />} errorElement={<ErrorPage />} />
            <Route path="mfuncstations" element={<MfuncStations />} errorElement={<ErrorPage />} />

            {/* <Route path="game" element={<Game />} errorElement={<ErrorPage />} /> */}
            <Route path="*" element={<ErrorPage errorCode="404" />} />
         </Route>
      </>
   ),
   // { basename: "/", }
);

export default App;
