import React from 'react';
import { NavLink } from 'react-router-dom'

const mainMenu = [
  {
    "name": "Администрирование",
    "key": "menu_control",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Состояние ЛСН",
        "key": "menu_status_lsn",
        "icon": "icon-people",
        "action": "/lsnstate",
        "rules": "rules"
      },
      {
        "name": "Связь с МС",
        "key": "menu_connect_mc",
        "icon": "icon-people",
        "action": "/msconnect",
        "rules": "rules"
      },
      {
        "name": "Связь с КНАП",
        "key": "menu_connect_knap",
        "icon": "icon-people",
        "action": "/knapdata",
        "rules": "rules"
      },
      {
        "name": "Связь с СЧ ПУ-ЛСН",
        "key": "menu_connect_sch_pylsn",
        "icon": "icon-people",
        "action": "/chpylsnconnect",
        "rules": "rules"
      },
      {
        "name": "Связь с ВПУ ЛСН",
        "key": "menu_connect_vpylsn",
        "icon": "icon-people",
        "action": "/vpylsnconnect",
        "rules": "rules"
      }
    ]
  },
  {
    "name": "Состояние серверов",
    "key": "menu_statusServ",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Объекты доступа",
        "key": "menu_access_objects",
        "icon": "icon-people",
        "action": "/access_objects",
        "rules": "rules"
      },
      {
        "name": "Виртуальная среда",
        "key": "menu_virtual",
        "icon": "icon-people",
        "action": "/virtual_env",
        "rules": "rules"
      },
      {
        "name": "События",
        "key": "menu_events",
        "icon": "icon-people",
        "action": "/loginhistory",
        "rules": "rules"
      }
    ]
  },
  {
    "name": "Конфигурирование",
    "key": "spr_conf",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "ЛСН",
        "key": "menu_lsn",
        "icon": "icon-info",
        "action": "/lsn",
        "rules": "spr"
      },
      {
        "name": "Многофункциональные станции",
        "key": "menu_mult_stations",
        "icon": "icon-info",
        "action": "/mfuncstations",
        "rules": "spr"
      }
    ]
  },
  {
    "name": "Протоколы",
    "key": "spr_protocols",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Данные НАП",
        "key": "napdata",
        "icon": "icon-info",
        "action": "/napdata",
        "rules": "spr"
      },
      {
        "name": "Данные МС",
        "key": "menu_datams",
        "icon": "icon-info",
        "action": "/msdata",
        "rules": "spr"
      },
      {
        "name": "Данные СЧ ПУ ЛСН ",
        "key": "menu_sch_pylsn",
        "icon": "icon-info",
        "action": "/chpylsndata",
        "rules": "spr"
      },
      {
        "name": "Данные ВПУ",
        "key": "menu_vpydata",
        "icon": "icon-info",
        "action": "/vpydata",
        "rules": "spr"
      }
    ]
  },
  {
    "name": "Настройки",
    "key": "menu_admin",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Доступы",
        "key": "menu_rules",
        "icon": "icon-note",
        "action": "/rules",
        "rules": "rules"
      },
      {
        "name": "Роли",
        "key": "menu_roles",
        "icon": "icon-grid",
        "action": "/roles",
        "rules": "rules"
      },
      {
        "name": "Сотрудники",
        "key": "menu_sys_users",
        "icon": "icon-people",
        "action": "/sys_users",
        "rules": "rules"
      },
      // {
      //   "name": "История входов",
      //   "key": "menu_login_history",
      //   "icon": "icon-people",
      //   "action": "/loginhistory",
      //   "rules": "rules"
      // }
    ]
  },
]

const MenuItems = (props) => {
  if (props.items === null) return null;
  console.log(props.items);
  let v_ret = props.items.map(function (item, index) {
    if ("submenu" in item)
      return (
        <li key={item.key} className="dropdown"><a href="#"><i className={"me-2 " + item.icon}></i>{item.name}</a>
          <ul>
            <MenuItems items={item.submenu} />

          </ul>
        </li>
      );
    else
      return (
        <li key={item.key}><NavLink to={item.action}><i className={"me-2 " + item.icon}></i>{item.name}</NavLink></li>
      );
  });
  return v_ret;
}

const Menu = (props) => {
  console.log(props.menu);
  return (
    <div className="sidebar pt-0" data-bs-theme={(props.settings.themeColor ?? "light") === "light" ? "light" : "dark"}>
      <div className="site-width">
        <ul id="side-menu" className="sidebar-menu">
          {/* <MenuItems items={props.menu} /> */}
          <MenuItems items={mainMenu} />
        </ul>
      </div>
    </div>
  );
}

export default React.memo(Menu);
