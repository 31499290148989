import { useState, useEffect } from "react";
import { requestToServer } from "../utils/appUtils";
import CardHeaderDashboard from "./cardHeaderDashboard";

let initData = {
  os: { name: "Linux 5.4.0-120-generic #136-Ubuntu SMP Fri Jun 10 13:40:48 UTC 2022 x86_64" /* uname -s -r -v -p */ },
  cpu: { used: "2.25%", name: "Intel(R) Xeon(R) CPU E5-2630 v2 @ 2.60GHz" },/* cat /proc/cpuinfo | grep 'model name' | uniq */
  ram: { used: "1 026 333К", total: "4 026 032К", used_pr: "25%" },
  storage: { used: "13 000 000К", free: "267 000 000К", used_pr: "5%" }

}

const CardAbonents = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);

  // const refreshData = () => {
  //   setLoading(true);
  //   requestToServer(props.url, { method: 'GET' }, (response) => {
  //     setData(response.data);
  //     setLoading(false);
  //   }, (error) => { setLoading(false); console.error('dashboard', error) }, false);
  // }

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    //    refreshData();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 650);
  }

  // useEffect(() => {
  //   refreshData();
  // }, []);

  let styleCard = {};
  let classCard = "card h-100";
  if (props.dashboard === false) {
    styleCard = { minHeight: "var(--clientheight)" };
    classCard = "card border-0";
  }

  return (
    <div className={classCard} style={styleCard}>
      <CardHeaderDashboard loading={loading} onClick={handleClick}>Конфигурация информационной системы</CardHeaderDashboard>
      <div className="card-body">
        <div className="text-center" style={{ fontSize: "0.9rem" }}>{data.os.name}</div>

        <div className="mt-3"><span>{data.cpu.name}:&nbsp;</span> <span className="text-danger1 fw-bold">{data.cpu.used}</span> </div>
        <div className="progress" role="progressbar" >
          <div className="progress-bar bg-warning" style={{ width: data.cpu.used }}></div>
        </div>

        <div className="mt-3">Память: используется <span className="text-danger1 fw-bold">{data.ram.used}</span> из  <span className="text-danger1 fw-bold">{data.ram.total}</span></div>
        <div className="progress" role="progressbar" >
          <div className="progress-bar" style={{ width: data.ram.used_pr }}></div>
        </div>

        <div className="mt-3">Диск: занято - <span className="text-danger1 fw-bold">{data.storage.used}</span>, свободно <span className="text-danger1 fw-bold">{data.storage.free}</span></div>
        <div className="progress" role="progressbar" >
          <div className="progress-bar bg-success" style={{ width: data.storage.used_pr }}></div>
        </div>

      </div>
    </div>
  );
}

export default CardAbonents;